<form [formGroup]="form">
  <mat-form-field [formGroupName]="nestedGroupName" appearance="outline" class="width-match-parent">
    <mat-label>{{ label | translate }}</mat-label>
    <input [formControlName]="formCtrlName" [matAutocomplete]="auto" matInput />
    <mat-error>{{ error | translate }}</mat-error>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ getValue(option) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
