<h1 *ngIf="!data" mat-dialog-title>{{ 'SESSIONS.NEW_SESSION_DIALOG.title' | translate }}</h1>
<h1 *ngIf="data" mat-dialog-title>{{ 'SESSIONS.FAIR_EDIT_DIALOG.title' | translate }}</h1>
<form (submit)="submit()" [formGroup]="form" mat-dialog-content>
  <div class="p-v-2" fxLayoutAlign="center start">
    <recrewt-image-selector
      (fileDeleted)="deleteLogo()"
      (fileSelected)="selectLogo($event)"
      *ngIf="form.value.mode === 1"
      [imageUrl]="logoUrl"
      appearance="circle"
      icon="photo"
    ></recrewt-image-selector>
  </div>
  <p *ngIf="!data">{{ 'SESSIONS.NEW_SESSION_DIALOG.message' | translate }}</p>
  <p *ngIf="data">{{ 'SESSIONS.FAIR_EDIT_DIALOG.message' | translate }}</p>
  <mat-form-field appearance="outline" class="block">
    <input formControlName="name" matInput />
    <mat-error>{{ 'SESSIONS.NEW_SESSION_DIALOG.formErrorMessage' | translate }}</mat-error>
    <mat-label>{{ 'SESSIONS.NEW_SESSION_DIALOG.label' | translate }}</mat-label>
  </mat-form-field>
  <p>
    <mat-checkbox
      *ngIf="isAdmin && !data"
      [control]="form.controls.mode"
      [falseValue]="0"
      [trueValue]="1"
      formControlName="mode"
    >
      {{ 'SESSIONS.NEW_SESSION_DIALOG.fair_check_box_text' | translate }}
    </mat-checkbox>
  </p>
  <ng-container *ngIf="form.value.mode === 1">
    <mat-form-field *ngIf="!data" appearance="outline" class="block">
      <input formControlName="pin" matInput />
      <mat-error>{{ 'SESSIONS.NEW_SESSION_DIALOG.pin_error' | translate }}</mat-error>
      <mat-label>{{ 'SESSIONS.NEW_SESSION_DIALOG.pin_title' | translate }}</mat-label>
    </mat-form-field>
    <recrewt-location-selector
      (click)="$event.stopPropagation()"
      class="width-match-parent"
      error="SESSIONS.NEW_SESSION_DIALOG.fair_location_error"
      formControlName="location"
      label="SESSIONS.NEW_SESSION_DIALOG.fair_location_title"
    ></recrewt-location-selector>
    <div class="m-b-2">
      <h3>Messetage</h3>
      <recrewt-date-time-range-picker formControlName="dates"></recrewt-date-time-range-picker>
    </div>

    <section fxLayout="column" fxLayoutGap="12px">
      <h3>Region</h3>
      <div>
        <mat-select formControlName="locale" placeholder="Region">
          <mat-option value="by">Bayern</mat-option>
          <mat-option value="bw">Baden-Württemberg</mat-option>
        </mat-select>
      </div>
      <h3>Erlaubte Eingabefelder auswählen:</h3>
      <div formArrayName="allowBoothData">
        <h4>Zum Messebesuch:</h4>
        <p *ngFor="let data of AllowedBoothData; let i = index">
          <mat-checkbox
            [control]="form.controls.allowBoothData.at(i)"
            [falseValue]="null"
            [formControlName]="i"
            [trueValue]="data"
          >
            {{ 'SESSIONS.NEW_SESSION_DIALOG.BOOTH.' + data | translate }}
          </mat-checkbox>
        </p>
      </div>
      <div formArrayName="allowCompanyData">
        <h4>Zum Unternehmen:</h4>
        <p *ngFor="let data of AllowedCompanyData; let i = index">
          <mat-checkbox
            [control]="form.controls.allowCompanyData.at(i)"
            [falseValue]="null"
            [formControlName]="i"
            [trueValue]="data"
          >
            {{ 'SESSIONS.NEW_SESSION_DIALOG.COMPANY.' + data | translate }}
          </mat-checkbox>
        </p>
      </div>
      <div formArrayName="allowJobData">
        <h4>Zu den Stellenangeboten:</h4>
        <p *ngFor="let data of AllowedJobData; let i = index">
          <mat-checkbox
            [control]="form.controls.allowJobData.at(i)"
            [falseValue]="null"
            [formControlName]="i"
            [trueValue]="data"
          >
            {{ 'SESSIONS.NEW_SESSION_DIALOG.JOB.' + data | translate }}
          </mat-checkbox>
        </p>
      </div>
    </section>
  </ng-container>
</form>

<div fxLayoutAlign="end center" mat-dialog-actions>
  <div>
    <button (click)="onNoClick()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
    <button
      (click)="submit()"
      [disabled]="!isFormValid"
      color="accent"
      mat-flat-button
      type="submit"
    >
      {{ 'COMMON.confirm' | translate }}
    </button>
  </div>
</div>
