import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { CompanyActions } from '@data/company/company.actions';
import { CompanyService } from '@data/company/company.service';
import { CompanyStore } from '@data/company/company.store';
import { log } from '../../testing/DevUtils/logging';
import { dropFields } from '@shared/util/array.util';
import { HttpUtils } from '@shared/util/http.util';
import { Company } from '@data/company/company.model';
import { CompanyQuery } from '@data/company/company.query';

@Injectable({ providedIn: 'root' })
export class CompanyEffects {
  load = createEffect((actions) => {
    return actions.pipe(
      ofType(CompanyActions.load),
      switchMap((request) => {
        this.companyStore.setLoading(true);
        return this.companyService
          .get(dropFields(request, ['type']))
          .pipe(HttpUtils.catchAndPrintError());
      }),
      tap((companys) => {
        this.companyStore.setLoading(false);
        if (!companys) return;

        if (!this.companyQuery.getAll().length) {
          this.companyStore.set(companys);
        } else {
          companys.forEach((company: Company) => this.companyStore.upsert(company.id, company));
        }
      }),
    );
  });

  update = createEffect((actions) =>
    actions.pipe(
      ofType(CompanyActions.updateExternal),
      switchMap((request) => {
        return this.companyService.update(request.targetId, request.input).pipe(
          tap(() =>
            this.companyStore.upsert(request.targetId, {
              ...request.input,
            }),
          ),
        );
      }),
    ),
  );

  updatePublic = createEffect((actions) =>
    actions.pipe(
      ofType(CompanyActions.updatePublic),
      switchMap((request) => {
        return this.companyService.update(request.targetId, request.input, true).pipe(
          tap(() =>
            this.companyStore.upsert(request.targetId, {
              ...request.input,
            }),
          ),
        );
      }),
    ),
  );

  create = createEffect((actions) =>
    actions.pipe(
      ofType(CompanyActions.createExternal),
      switchMap((request) => {
        return this.companyService.create(request);
      }),
      tap((company) => {
        log.call(this, company, 'newly created external Company');
        if (!company) return;
        this.companyStore.add(company);
      }),
    ),
  );

  // batchCreate = createEffect((actions) =>
  //   actions.pipe(
  //     ofType(CompanyActions.batchCreateExternal),
  //     switchMap((request) => {
  //       return this.companyService.batchCreateWithForeignId(request);
  //     }),
  //     tap((companys) => {
  //       log.call(this, companys, 'newly created external Companys');
  //       if (!companys.length) return;
  //       this.companyStore.add(companys);
  //     }),
  //   ),
  // );

  delete = createEffect((actions) => {
    return actions.pipe(
      ofType(CompanyActions.deleteExternal),
      switchMap((request) => {
        return this.companyService.delete(request.targetId);
      }),
    );
  });

  constructor(
    private companyStore: CompanyStore,
    private companyService: CompanyService,
    private companyQuery: CompanyQuery,
  ) {}
}
