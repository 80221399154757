import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { JobGeneralState, JobGeneralStore } from './job-general.store';
import { GeneralJobType, JobGeneral } from '@data/job-general/job-general.model';
import { ObservableUtil } from '@shared/util/observable.util';

@Injectable({ providedIn: 'root' })
export class JobGeneralQuery extends QueryEntity<JobGeneralState, JobGeneral> {
  selectWhenLoaded$ = ObservableUtil.takeWhenTruthy(this.selectLoading(), (it) => !it);

  generalJobs$ = this.selectAll({ filterBy: (it) => it.type === GeneralJobType.BOOKABLE });

  courses$ = this.selectAll({ filterBy: (it) => it.type === GeneralJobType.STUDY_INFO });

  constructor(protected override store: JobGeneralStore) {
    super(store);
  }

  selectSpecializationsForJob(jobType: string) {
    return this.selectAll({ filterBy: (it) => it.type === 4 && it.parentJobId === jobType });
  }
}
