import { SessionStatus } from '@core/enums/session-status';
import { Participant } from '@data/participant/participant.model';

export type SessionUser = Pick<Participant, '_id' | 'name'>;

export interface IDateRange {
  start: string;
  end: string;
}

export interface MatchingSession {
  id: string;
  pin: string;
  name: string;
  team: string;
  createdAt: Date;
  closedAt: Date;
  date: Date;
  dates: IDateRange[];
  status: SessionStatus;
  users: SessionUser[];
  mode: SessionType;
  fullOnboarding: boolean;
  location?: Location;
  image?: string;
  locale?: 'by' | 'bw';
  allowBoothData?: AllowedBoothData[];
  allowCompanyData?: AllowedCompanyData[];
  allowJobData?: AllowedJobData[];
}

export interface ICreateSessionRequest {
  name: string;
  mode?: SessionType;
  pin?: string;
  dates: IDateRange[];
  location?: Location;
  image?: string;
  locale?: 'by' | 'bw';
  allowBoothData?: AllowedBoothData[];
  allowCompanyData?: AllowedCompanyData[];
  allowJobData?: AllowedJobData[];
}

export interface IUpdateSessionRequest {
  name: string;
  dates: IDateRange[];
  location?: Location;
  image?: string;
  locale?: 'by' | 'bw';
  allowBoothData?: AllowedBoothData[];
  allowCompanyData?: AllowedCompanyData[];
  allowJobData?: AllowedJobData[];
}

export enum SessionType {
  DEFAULT,
  FAIR,
}

export const isFairSession = (s: MatchingSession): boolean => {
  return s.mode === SessionType.FAIR;
};

export const isRunningSession = (s: MatchingSession): boolean => {
  return s.status === SessionStatus.ACTIVE;
};

export const isClosedSession = (s: MatchingSession): boolean => {
  return s.status === SessionStatus.CLOSED;
};

//todo: improve session Id validation
export const isValidSessionId = (sessionId: MatchingSession['id']): boolean => {
  return sessionId.length === 24;
};

export interface Location {
  address?: string;
  region?: string;
  latitude?: number;
  location: {
    type: string;
    coordinates: [number, number];
  };
  longitude?: number;
}

export enum AllowedBoothData {
  BOOTH_NR = 'boothNr',
}

export enum AllowedCompanyData {
  BRANCH = 'branch',
  URL = 'url',
  LOCATION = 'location',
  SIZE = 'size',
  CONTACT = 'contact',
  THEME = 'theme',
}

export enum AllowedJobData {
  URL = 'url',
  DESC = 'desc',
  EDUCATION_LEVEL = 'educationLevel',
  SCHOLASTIC_DATA = 'scholasticData',
  SALARY = 'estimatedSalaryEUR',
  VACATION = 'vacation',
  BENEFITS = 'benefits',
  CONTACT = 'contact',
  INTERNSHIP_POSSIBLE = 'internshipPossible',
  TOPICS = 'topics',
}
