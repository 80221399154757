import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_DATE_LOCALE, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { NameLetterAvatarComponent } from '@shared/components/name-letter-avatar/name-letter-avatar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FnPipe } from '@shared/pipes/fn.pipe';
import { TableCellDirective } from '@shared/directives/table-cell.directive';
import { EmptyPageComponent } from '@shared/components/placeholder/empty-page/empty-page.component';
import { IterablePipe } from '@shared/pipes/iterable.pipe';
import { TableComponent } from '@shared/components/table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { InfoSectionComponent } from '@shared/components/placeholder/info-section/info-section.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FilterButtonComponent } from '@shared/components/filter/components/filter-button/filter-button.component';
import { FilterGroupComponent } from '@shared/components/filter/components/filter-group/filter-group.component';
import { MatBadgeModule } from '@angular/material/badge';
import { PopupComponent } from '@shared/components/popup/base/popup.component';
import { FilterComponent } from '@shared/components/filter/components/filter/filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { SelectComponent } from '@shared/components/popup/select/select.component';
import { SearchPopupHandlerComponent } from '@shared/components/popup/base/search-popup-handler.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InputDialogComponent } from '@shared/components/dialog/input-dialog/input-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { ContextMenuComponent } from '@shared/menus/context-menu/context-menu.component';
import { SectionSideNavComponent } from '@shared/components/side-nav/section-side-nav/section-side-nav.component';
import { TemplateHostDirective } from '@shared/directives/template-host.directive';
import { SectionContentComponent } from '@shared/components/side-nav/section-content/section-content.component';
import { SectionComponent } from '@shared/components/side-nav/section/section.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { UserFormService } from '@core/services/user/user-form.service';
import { ConfirmDialogComponent } from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderDirective } from './directives/loader.directive';
import { SocketioConnectionIndicatorComponent } from './components/socketio-connection-indicator/socketio-connection-indicator.component';
import { BooleanSignComponent } from './components/boolean-sign/boolean-sign.component';
import { StatusTagComponent } from '@shared/components/filter/components/status-tag/status-tag.component';
import { StatusTagPipe } from '@shared/pipes/status-tag.pipe';
import { TypeItemComponent } from '@shared/components/type-item/type-item.component';
import { StageItemComponent } from '@shared/components/stage-item/stage-item.component';
import { LocationSelectorComponent } from '@shared/components/editor/location-selector/location-selector.component';
import { ChipsInputComponent } from '@shared/components/dialog/inputs-dialog/input-types/chips-input/chips-input.component';
import { ImportInputComponent } from '@shared/components/dialog/inputs-dialog/input-types/import-input/import-input.component';
import { SafePipe } from '@shared/pipes/safe.pipe';
import { TeamDialogComponent } from './components/dialog/team-dialog/team-dialog.component';
import { RememberSortDirective } from './directives/remember-sort.directive';
import { TakeFocusDirective } from '@shared/directives/take-focus.directive';
import { CategoricalChipSelectorGroupComponent } from '@shared/components/categorical-selector-group/categorical-chip-selector-group.component';
import { ChipEditorComponent } from '@shared/components/chip-editor/chip-editor.component';
import { DigitOnlyDirective } from '@shared/directives/digit-only.directive';
import { CheckboxValueDirective } from './directives/checkbox-value.directive';
import { AllowInputDirective } from './directives/allow-input.directive';
import { ExportDialogComponent } from './components/dialog/export-dialog/export-dialog.component';
import { DateTimeRangePickerComponent } from './components/editor/date-time-range-picker/date-time-range-picker.component';

@NgModule({
  declarations: [
    NameLetterAvatarComponent,
    TableCellDirective,
    FnPipe,
    EmptyPageComponent,
    IterablePipe,
    StatusTagPipe,
    TableComponent,
    InfoSectionComponent,
    FilterButtonComponent,
    FilterGroupComponent,
    PopupComponent,
    FilterComponent,
    SelectComponent,
    SearchPopupHandlerComponent,
    InputDialogComponent,
    ConfirmDialogComponent,
    ContextMenuComponent,
    SectionSideNavComponent,
    SectionComponent,
    SectionContentComponent,
    TemplateHostDirective,
    LoaderComponent,
    LoaderDirective,
    SocketioConnectionIndicatorComponent,
    BooleanSignComponent,
    StatusTagComponent,
    TypeItemComponent,
    StageItemComponent,
    LocationSelectorComponent,
    ChipsInputComponent,
    ImportInputComponent,
    SafePipe,
    TeamDialogComponent,
    RememberSortDirective,
    TakeFocusDirective,
    ChipEditorComponent,
    CategoricalChipSelectorGroupComponent,
    DigitOnlyDirective,
    CheckboxValueDirective,
    AllowInputDirective,
    AllowInputDirective,
    ExportDialogComponent,
    DateTimeRangePickerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
    FlexModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatOptionModule,
    MatSelectModule,
    MatExpansionModule,
    MatInputModule,
    ScrollingModule,
    MatRippleModule,
    MatDialogModule,
    MatMenuModule,
    MatStepperModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    FormsModule,
    ExtendedModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    NameLetterAvatarComponent,
    FnPipe,
    StatusTagPipe,
    TableComponent,
    TableCellDirective,
    FilterButtonComponent,
    PopupComponent,
    InputDialogComponent,
    ContextMenuComponent,
    SectionSideNavComponent,
    LoaderComponent,
    LoaderDirective,
    SocketioConnectionIndicatorComponent,
    BooleanSignComponent,
    StatusTagComponent,
    StageItemComponent,
    TypeItemComponent,
    ChipsInputComponent,
    ImportInputComponent,
    LocationSelectorComponent,
    SelectComponent,
    SafePipe,
    IterablePipe,
    ChipEditorComponent,
    CategoricalChipSelectorGroupComponent,
    DigitOnlyDirective,
    CheckboxValueDirective,
    AllowInputDirective,
    DateTimeRangePickerComponent,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' }, UserFormService],
})
export class SharedModule {}
