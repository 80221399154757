<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container *ngFor="let opt of menuOptions">
      <button (click)="opt.onClick($event)" mat-menu-item>
        <mat-icon>{{ opt.icon }}</mat-icon>
        <span>{{ opt.text | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<button
  (click)="$event.stopPropagation()"
  [disabled]="!menuOptions?.length"
  [matMenuTriggerFor]="contextMenu"
  mat-icon-button
>
  <mat-icon>more_horiz</mat-icon>
</button>
