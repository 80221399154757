import { IHTTPParamsObject } from '@data/entity-crud.service';
import { Location, MatchingSession } from '@data/session/session.model';
import { JobGeneral } from '@data/job-general/job-general.model';
import { Company } from '@data/company/company.model';
import { keepFields } from '@shared/util/array.util';

export interface CompanyData {
  name: string;
  url?: string;
  branch?: number;
  branches?: number;
  location?: Location;
  size?: number;
  contact?: {
    name: string;
    email: string;
    registrationEmail: string;
    phone: string;
  };
  jobContact?: {
    name: string;
    email: string;
    phone: string;
  };
  theme?: {
    primary: string;
    secondary: string;
  };
}

export interface Job {
  identifiableName?: string;
  url?: string;
  desc?: string;
  educationLevel?: number;
  specializations?: string[];
  scholasticData?: ScholasticData;
  estimatedSalaryEUR?: number[];
  salaryUnspecified?: boolean;
  internshipPossible?: boolean;
  vacation?: number;
  topics?: number;
  matchData: {
    general: { benefits: { [p: string]: number } };
    additionalData?: { benefits: { [p: string]: string[] } };
  };
}

export interface JobsData {
  [key: string]: Job;
}

export interface ScholasticData {
  schoolName?: string;
  schoolLocation?: Location;
  courseId?: string;
  courseName?: string;
  degree?: string;
  jobOpportunities?: string;
  phases?: string;
  semesterAbroad?: boolean;
}

export interface Attendant {
  /**The attendants fair-booth id */
  id: string;
  /**  The session id this fair-booth was created for. */
  session: string;

  /**  [Array of JobGeneral IDs the attendant offers ] */
  jobs?: JobGeneral['id'][];

  /** The names of staff members that are on the fair for this company string Full name of the staff member ] */
  staff?: string[];

  /**  The first day the attendant attends the fair. */
  fromDate?: Date;

  /**   The last day the attendant attends the fair. */
  toDate?: Date;

  /**  Indicates whether the attendant left the session/fair */
  exited?: boolean;

  /**  The attendants fair-booth number on the fair */
  boothNr?: string;

  /** True if attendant was added manually via MultiPlatform, false if company joined via CompanySuite */
  isManuallyAdded: boolean;

  company?: string;

  importedCompany?: string;

  checked?: boolean;
}

export type AttendantWithCompany = { attendant: Attendant; company?: Company };

export const getJobsAmount = (a: Attendant): number => a?.jobs?.length ?? 0;

export function createAttendant(params: Partial<Attendant>) {
  return { ...params } as Attendant;
}

export function cleanAttendantData(attendants: Attendant | Attendant[]) {
  return keepFields(attendants, ['jobs', 'fromDate', 'toDate', 'boothNr', 'importedCompany']);
}

export function cleanCompanyData(companies: Company | Company[]) {
  return keepFields(companies, ['companyData', 'jobsData']);
}

export interface ILoadAttendantsRequest extends IHTTPParamsObject {
  id: MatchingSession['id'];
}

export type IUpdateAttendantChanges = Partial<Pick<Attendant, 'boothNr'>> & {
  isManuallyAdded: false;
};

export type IUpdateExternalAttendantChanges = Partial<
  Pick<Attendant, 'boothNr' | 'toDate' | 'fromDate' | 'importedCompany' | 'jobs' | 'checked'>
> & { isManuallyAdded: true };

export interface ICreateExternalAttendantInput
  extends Pick<Attendant, 'boothNr' | 'fromDate' | 'toDate' | 'importedCompany' | 'jobs'> {}

export interface ICreateExternalAttendantInputWithCompany {
  attendant: Attendant;
  company: Company;
  aid: string;
}

export function populateAttendants(attendants: Attendant[], companies: Company[]) {
  return attendants.map((attendant) => {
    const company = companies.find(
      (c) => c.id === (attendant.importedCompany ?? attendant.company),
    );
    return { attendant, company };
  });
}
