import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { AttendantActions } from '@data/attendant/attendant.actions';
import { AttendantService } from '@data/attendant/attendant.service';
import { AttendantStore } from '@data/attendant/attendant.store';
import { log } from '../../testing/DevUtils/logging';
import { DELETE_OK } from '@data/entity-crud.service';
import { CompanyActions } from '@data/company/company.actions';
import { dropUndefined } from '@shared/util/array.util';
import { NotificationService } from '@core/services/notification/notification.service';

@Injectable({ providedIn: 'root' })
export class AttendantEffects {
  load = createEffect((actions) => {
    return actions.pipe(
      ofType(AttendantActions.load),
      switchMap((request) => {
        this.attendantStore.setLoading(true);
        return this.attendantService.getByForeignId(request.id);
      }),
      tap((attendants) => {
        this.attendantStore.set(attendants ?? []);
        this.attendantStore.setLoading(false);
      }),
      map((attendants) => {
        const ids = dropUndefined(attendants?.map((a) => a.company ?? a.importedCompany)) ?? [];
        CompanyActions.loadCompanies({ ids });
      }),
    );
  });

  update = createEffect((actions) =>
    actions.pipe(
      ofType(AttendantActions.update, AttendantActions.updateExternal),
      switchMap((request) => {
        return this.attendantService.updateWithForeignId(request).pipe(
          tap(() => {
            this.attendantStore.update(request.targetId, {
              ...request.input,
            });
          }),
        );
      }),
    ),
  );

  updatePublic = createEffect((actions) =>
    actions.pipe(
      ofType(AttendantActions.updatePublic),
      switchMap((request) => {
        return this.attendantService.updateWithForeignId(request, true).pipe(
          tap(() => {
            this.attendantStore.update(request.targetId, {
              ...request.input,
            });
          }),
        );
      }),
    ),
  );

  create = createEffect((actions) =>
    actions.pipe(
      ofType(AttendantActions.createExternal),
      switchMap((request) => {
        return this.attendantService.createWithForeignId(request);
      }),
      tap((attendant) => {
        log.call(this, attendant, 'newly created external Attendant');
        if (!attendant) return;
        this.attendantStore.add(attendant);
      }),
    ),
  );

  batchCreate = createEffect((actions) =>
    actions.pipe(
      ofType(AttendantActions.importExternal),
      switchMap((request) => {
        return this.attendantService.batchCreateWithForeignId(request);
      }),
      tap((attendants) => {
        log.call(this, attendants, 'newly created external Attendants');
        if (!attendants.length) return;
        this.attendantStore.add(attendants);
        const ids = dropUndefined(attendants.map((a) => a.importedCompany));
        CompanyActions.loadCompanies({ ids });
      }),
    ),
  );

  delete = createEffect((actions) => {
    return actions.pipe(
      ofType(AttendantActions.deleteExternal),
      switchMap((request) => {
        return this.attendantService.deleteWithForeignId(request).pipe(
          tap((response) => {
            if (!response || response.status !== DELETE_OK) return;
            this.attendantStore.update(request.targetId, { exited: true });
          }),
        );
      }),
    );
  });

  export = createEffect((actions) => {
    return actions.pipe(
      ofType(AttendantActions.exportExternal),
      map((request) => {
        const objectObservable = this.attendantService.exportToCompanySuite(request.aid);
        objectObservable.subscribe({
          next: () => {
            this.notifier.success('Aussteller erfolgreich in die Unternehmensplattform exportiert');
          },
          error: () => {
            this.notifier.error(
              'Fehler beim Exportieren des Ausstellers in die Unternehmensplattform',
            );
          },
        });
        return objectObservable;
      }),
    );
  });

  constructor(
    private attendantStore: AttendantStore,
    private attendantService: AttendantService,
    private notifier: NotificationService,
  ) {}
}
