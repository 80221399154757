import { Component, Input, OnInit } from '@angular/core';
import { MatchingSession } from '@data/session/session.model';
import { MatchingSessionOptions } from '@shared/menus/context-menu/context-menu-option/matching-session-options';
import { ContextMenuOption, ContextMenuTypes } from './context-menu-option/context-menu-option';
import { AttendantWithCompany } from '@data/attendant/attendant.model';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { AttendantMenuOptions } from '@shared/menus/context-menu/context-menu-option/attendant-menu-options';
import { NotificationService } from '@core/services/notification/notification.service';
import { IonosFacadeService } from '@core/services/upload/ionos-facade.service';
import getSessionMenuOptions = MatchingSessionOptions.getSessionMenuOptions;
import getAttendantMenuOptions = AttendantMenuOptions.getAttendantMenuOptions;

@Component({
  selector: 'recrewt-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent<Target> implements OnInit {
  menuOptions: ContextMenuOption<any>[] | undefined;

  @Input() target: Target | undefined;

  @Input() menuType: ContextMenuTypes | undefined;

  constructor(
    private dialog: DialogService<any, string>,
    private notifier: NotificationService,
    private upload: IonosFacadeService,
  ) {}

  ngOnInit(): void {
    if (!!this.target) {
      switch (this.menuType) {
        case ContextMenuTypes.MATCHING_SESSION: {
          this.menuOptions = getSessionMenuOptions(
            this.target as unknown as MatchingSession,
            this.dialog,
            this.notifier,
            this.upload,
          );
          break;
        }
        case ContextMenuTypes.ATTENDANTS_TABLE: {
          this.menuOptions = getAttendantMenuOptions(
            this.target as unknown as AttendantWithCompany,
            this.dialog,
            this.notifier,
          );
          break;
        }

        default: {
          console.log('undefined context menu type');
          break;
        }
      }
    }
  }
}
