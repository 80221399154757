import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopupComponent } from '@shared/components/popup/base/popup.component';
import { get } from 'lodash';

@UntilDestroy()
@Component({
  template: '',
})
export class SearchPopupHandlerComponent
  extends PopupComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() labelKey?: string;

  @Input() idKey: string | null = 'id';

  @Input() loading$?: Observable<boolean>;

  @Input() options: any[] = [];

  @Input() model: any;

  @Input() optionTpl?: TemplateRef<any>;

  @Input() originTpl?: TemplateRef<any>;

  @Input() mapValueTo?: (value: any) => any;

  @Input() displayWith?: (value: any) => string;

  @Output() selectChange = new EventEmitter();

  searchControl = new UntypedFormControl();

  visibleOptions = 4;

  protected originalOptions: any[] = [];

  constructor(
    vcr: ViewContainerRef,
    zone: NgZone,
    cdr: ChangeDetectorRef,
    protected translate: TranslateService,
  ) {
    super(vcr, zone, cdr);
  }

  get label(): any {
    return this.displayValue(this.model);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'].currentValue) {
      this.originalOptions = [...this.options];
      requestAnimationFrame(() => (this.visibleOptions = this.options.length || 1));
    }
  }

  ngOnInit(): void {
    if (this.model) {
      this.initModel();
    }
    this.originalOptions = [...this.options];
    requestAnimationFrame(() => (this.visibleOptions = this.options.length || 1));

    this.searchControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((term) => this.search(term));
  }

  search(value: string): void {
    this.options = this.originalOptions.filter((option) => {
      const terms = value.trim().split(' ') ?? [];
      return terms.every((it) =>
        this.translate
          .instant(this.displayValue(option))
          .toLowerCase()
          .includes(it.toLowerCase().trim()),
      );
    });
    requestAnimationFrame(() => {
      this.visibleOptions = this.options.length || 1;
      this.cdr.detectChanges();
    });
  }

  displayValue(option: any): any {
    if (!this.labelKey && !this.displayWith) {
      return option;
    }
    if (this.displayWith) {
      return this.displayWith(option);
    }
    return option ? get(option, this.labelKey!) : option;
  }

  optionValue(option: any): any {
    if (!this.mapValueTo) {
      return option;
    }
    return option ? this.mapValueTo(option) : option;
  }

  optionId(option: any): any {
    if (!this.idKey) {
      return option;
    }
    return option ? option[this.idKey] : option;
  }

  override close() {
    super.close();
    this.searchControl.patchValue('');
  }

  select(option: any): void {
    if (this.optionValue(option) !== this.optionValue(this.model)) {
      this.model = option;
      this.selectChange.emit(this.optionValue(option));
      this.onChanged(this.optionValue(option));
    }
    this.close();
  }

  isActive(option: any): boolean {
    if (!this.model) {
      return false;
    }
    return this.optionId(option) === this.optionId(this.model);
  }

  override open(dropdownTpl: TemplateRef<any>, origin: HTMLElement) {
    requestAnimationFrame(() => (this.visibleOptions = this.options.length || 1));
    super.open(dropdownTpl, origin);
  }

  override writeValue(obj: any): void {
    if (obj == null) {
      return;
    }
    this.initModel();
    this.select(obj);
  }

  private initModel(): void {
    this.model = this.options.find((currentOption) =>
      this.idKey
        ? currentOption[this.idKey] === this.optionId(this.model)
        : currentOption === this.model,
    );
  }
}
