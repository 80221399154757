import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, first, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'recrewt-import-input[getOptions]',
  templateUrl: './import-input.component.html',
})
export class ImportInputComponent implements OnInit {
  @Input() form!: UntypedFormGroup;

  @Input() formCtrlName = '';

  @Input() nestedGroupName: string | null = null;

  @Input() error = 'ChipsInputComponentLABEL NOT SET ERROR MESSAGE NOT SET';

  @Input() label: string = 'ChipsInputComponentLABEL NOT SET';

  @Input() getOptions!: (searchText: string) => Observable<any[] | undefined>;

  @Input() getValue!: (obj: any) => string;

  @Output() optionSelected: EventEmitter<any> = new EventEmitter();

  options: any[] = [];

  filteredOptions = of(this.options);

  formControl!: AbstractControl;

  private optionValueSelected = false;

  private AUTOCOMPLETE_DEBOUNCE_TIME = 500;

  ngOnInit(): void {
    if (!this.nestedGroupName) {
      this.formControl = this.form.get(this.formCtrlName)!;
    } else {
      this.formControl = this.form.get(this.nestedGroupName + '.' + this.formCtrlName)!;
    }
    this.formControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(this.AUTOCOMPLETE_DEBOUNCE_TIME),
        map((value) => {
          if (typeof value !== 'string') {
            this.optionValueSelected = true;
            const r = this.getValue(value);
            this.formControl.setValue(r);
            return r;
          } else {
            this.optionValueSelected = false;
            return value;
          }
        }),
      )
      .subscribe((value: string) => {
        if (!this.optionValueSelected && value.length > 2) {
          this.onInputChanges(value);
        }
      });
  }

  onInputChanges(value: string) {
    this.getOptions(value)
      .pipe(first())
      .subscribe((x) => {
        if (!!x) this.onOptionsLoaded(x, value);
      });
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent) {
    this.optionValueSelected = true;
    this.optionSelected.emit($event.option.value);
  }

  onOptionsLoaded(options: any[], searchText: string) {
    this.options = options.length ? [...options, searchText] : [searchText];
    this.filteredOptions = of(this.options);
    // of(
    //   this.options.filter((option) =>
    //   {this.getValue(option).toLowerCase().includes(searchText.toLowerCase()),
    //   ),
    // );
  }
}
