import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RollbarService } from '../../../app.module';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '@core/services/notification/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private notify: NotificationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const rollbar = this.injector.get(RollbarService);
        let errorMessage;
        if(!error.status) {
           throw error;
        }
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error ${error.status}: ${error.statusText}`;
        } else {
          errorMessage = `Error ${error.status}: ${error.statusText}`;
        }
        this.notify.error(errorMessage);

        if (!environment.production) {
          console.error(error);
        } else {
          rollbar.error(error);
        }
        return throwError(errorMessage);
      }),
    );
  }
}
